import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import './App.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path={"/order/edit/:hash"} children={<EditOrder />}/>
      </Switch>
    </Router>

  );
}

const minutesSinceOrderPlaced = (timestamp) => {
  const now = new Date(Date.now()).getTime();
  const placed = new Date(timestamp).getTime();
  const millisecondsPassed = (placed - now);
  return Math.abs(millisecondsPassed / 60000);
}

const canEditOrder = (timestamp, maxEditTime) => {
  return Math.abs(minutesSinceOrderPlaced(timestamp)) < maxEditTime;
}

const timeLeftToCancel = (timestamp, maxEditTime) => {
  return Math.abs((maxEditTime - minutesSinceOrderPlaced(timestamp)));
}

const TimeLeft = (props) => {
  const { time } = props;
  const days = Math.abs(Math.floor(time/24/60));
  const hours = Math.floor(time/60%24);
  const minutes = Math.floor(time%60);
  return (
    <span>
      {days > 0 ? `${days} days ` : ''}
      {hours > 0 ? `${hours} hours `: ''}
      {minutes > 0 ? `${minutes} minutes `: ''}
    </span>
  );
};

function EditOrder() {
  let query = useQuery();
  let { hash } = useParams();
  const [settings, setSettings] = useState(false);
  const [cart, setCart] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleCancelOrder = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/cancel`, {
      shop: query.get('shop'),
      hash: hash
    }).then((response) => {
      console.log(response);
      window.location.href = settings.redirectUrl+`/?token=${cart.cart}`
    })
  }

  const handleClose = () => {
    window.location.href = settings.redirectUrl
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/settings/${query.get('shop')}`).then((response) => {
      //console.log(JSON.stringify(response.data, null, 2));
      setSettings(response.data);
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart/${hash}/${query.get('shop')}`).then((response) => {
        //console.log(JSON.stringify(response.data, null, 2));
        setCart(response.data);
      }).catch((error) => {
        console.log(error);
        setCart(false);
      }).then(() => {
        setLoading(false);
      })
    }).catch((error) => {
      console.log(error);
      setSettings(false);
      setCart(false);
    });
  }, []);

  return (
    <div className={"App"}>
      <div hidden={!loading}>
        Loading...
      </div>
      <div hidden={loading}>
        <div hidden={!cart || !canEditOrder(cart.timestamp, settings.maxEditTime)}>
          <p>Time Left to Cancel: <TimeLeft time={timeLeftToCancel(cart.timestamp, settings.maxEditTime)}/></p>
          <p>Editing your order will effectively cancel the order and allow you to replace it.</p>
          <p>Do you wish to continue?</p>
          <div>
            <button onClick={handleCancelOrder}>Yes, cancel my order</button><br/><br/>
            <button onClick={handleClose}>No, I want to keep my order</button>
          </div>
        </div>
        <div hidden={loading || (cart && canEditOrder(cart.timestamp, settings.maxEditTime))}>
          <p>Order not found</p>
        </div>
      </div>
    </div>
  )
}
export default App;
